import React, { useState, useEffect } from 'react';

import '@fontsource/open-sans';
import '@fontsource/montserrat';

import "./App.css"

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

import routes from './routes';

import { Box, Button } from '@mui/material';

// General components
import ElevateAppBar from "components/ElevateAppBar";
import Footer from "components/Footer";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Determine if the current route is the Home page
  const isHomePage = pathname === '/';

  const getRoutes = (routes) => {
    return routes.map((route) => {
      const mainRoute = route.route ? (
      <Route
        key={route.key}
        path={route.route}
        element={route.component}
      />
    ) : null;

    if (route.pages) {
      const subRoutes = route.pages.map((page) => (
        <Route
          key={page.key}
          path={page.route}
          element={page.component}
        />
      ));
      return [mainRoute, ...subRoutes];
    }

    return mainRoute;
    })
  };

  const [data, setData] = useState('');
  useEffect(() => {
    (async function fetchData() {
      try {
        const response = await fetch('/api/weathermusic');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <ElevateAppBar disableScrollEffect={!isHomePage} />

          <Box sx={{ flex: 1 }}>
            <Routes>
              {getRoutes(routes)} {/* Dynamically render routes */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
      {/* Data Display */}
      {data && (
        <>
          <div>{data?.title}</div>
          <div>{data?.artist} - {data?.album}</div>
          <Button href={data?.preview} disabled={data?.preview === null}>Preview</Button>
          <Button href={data?.tracklink} disabled={data?.preview === null}>Tracklink</Button>
        </>
      )}
    </>
  );
}
