import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorFallback = ({ error, onRetry }) => {
    const navigate = useNavigate();

    return (
        <div style={{ padding: '1rem', textAlign: 'center' }}>
            <h1>Oops! Something went wrong.</h1>
            <p>{error?.message || 'An unexpected error occurred.'}</p>
            <div style={{ marginTop: '1rem' }}>
                <button style={{ marginRight: '1rem' }} onClick={() => navigate('/')}>
                    Go Home
                </button>
                <button onClick={onRetry}>Retry</button>
            </div>
        </div>
    );
};

export default ErrorFallback;