import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('ErrorBoundary caught an error:', error, errorInfo);
        // Optional: Log the error to an external service
    }

    resetError = () => {
        this.setState({ hasError: false, error: null });
    };

    render() {
        if (this.state.hasError) {
            const FallbackComponent = this.props.fallback; // Assign fallback component
            return (
                <FallbackComponent
                    error={this.state.error}
                    onRetry={this.resetError}
                />
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;