import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  Container,
  ImageList,
  ImageListItem
} from "@mui/material";

import colors from "assets/theme/base/colors";


// import "./styles.css";
import YoutubeEmbed from "../components/YoutubeEmbed";
import { Image } from "@mui/icons-material";

const aporiaBeyondTheValley = {
  href: "portfolio/aporia-beyond-the-valley/",
  img: "/assets/images/header.jpg",
  title: "Aporia - Beyond the Valley",
  description: "Aporia: Beyond The Valley is an award-winning single-player first-person mystery adventure game set in a detailed open world accompanied by a breathtaking soundtrack. Aporia brings a unique approach to […]"
};

const AporiaOverview = () => {

  const inGameMedia = [
    {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_f3d58bfe36cafe47fe7dca878afa830f6afa26cf.jpg?resize=1920%2C1080&#038;ssl=1"
    },
    {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_ddd4fca0ab3656b6852eb187f4425fc880d168d1.jpg?resize=1920%2C1080&#038;ssl=1"
    },
    {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_63e9c9adade271321e3965e754e994939b3e4d1e.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_41a3f0698fb9fe2fc2cf4203ef9877f81d9613dd.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_9a76bc1592547245ec569dfc67fecf51d335ea80.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2018/05/ss_8ecbb0ffe3edbe385dc4598a2173fbaac5fc3ae9.jpg?resize=1920%2C1080&#038;ssl=1"
    }
  ]

  const inGameUI = [
    {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523204853_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523204903_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523204920_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523204931_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523205034_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523205045_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523205615_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523205903_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }, {
      img: "https://i0.wp.com/mikaelpeterolsen.com/wp-content/uploads/2019/05/20190523205939_1.jpg?resize=1920%2C1080&#038;ssl=1"
    }
  ]

  const { secondary, primary } = colors;

  return (
    <Box>
      <Container>
        <Typography variant="h3" component="div" gutterBottom>
          Aporia: Beyond The Valley
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          <strong>Aporia: Beyond The Valley</strong> is an award winning single-player first person mystery adventure game set a detailed open world accompanied by a breathtaking soundtrack. Aporia brings a unique approach to the art of storytelling, by not using text or dialogue. The elaborate story is told through the visuals, music and animated projections alone. The game offers new and interesting puzzles embedded within the immersive AAA-like environment.
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          You awake abandoned after hundreds of years of sleep in a crumbling temple of a once mighty civilisation, with no memory of your past. Outside, you discover a ruined and overgrown city. As you explore deeper into the forests and the many ruins, you reveal the macabre secrets of this haunted, forgotten world. Immersed in this world of dark beauty, thrilling mystery and ancient structures, you embark on a quest to discover the truth about who you are, and what happened to this magnificent ancient world.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Official Trailer
        </Typography>

        <YoutubeEmbed embedId="QGYntByAF3w" />

        <Typography variant="h4" gutterBottom>
          Media
        </Typography>
        <ImageList cols={2} rowHeight='auto' gap={4} variant='quilted'>
          {inGameMedia.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography variant="h4" gutterBottom>
          UI
        </Typography>
        <ImageList cols={2} rowHeight='auto' gap={4} variant='quilted'>
          {inGameUI.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography variant="body2" color="text.secondary" paragraph>
          <strong>Platform:</strong> PC | <strong>Genre:</strong> Adventure,
          Puzzle | <strong>Release Date:</strong> 19th July 2017
        </Typography>

        <Box>
          <Button
            href="http://www.aporiathegame.com/"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            sx={{
              ":hover": {
                backgroundColor: primary.main,
                borderColor: primary.main,
                color: primary.contrastText,
              }
            }}>
            Official Website
          </Button>
          <Button
            href="https://store.steampowered.com/app/573130/Aporia_Beyond_The_Valley/"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            sx={{
              ":hover": {
                backgroundColor: primary.main,
                borderColor: primary.main,
                color: primary.contrastText,
              }
            }}>
            Steam Page
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AporiaOverview;
export { aporiaBeyondTheValley };
