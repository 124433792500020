import {aporiaBeyondTheValley} from './aporia-beyond-the-valley-overview';
import uiWarOfRights from './ui-war-of-rights';
import photobashingLundbeck from './photobashing-lundbeck';

const portfolioItems = [
    aporiaBeyondTheValley,
    uiWarOfRights,
    photobashingLundbeck
];

export default portfolioItems;