import * as React from 'react';

import portfolioItems from '../../../data/index'; // Import the portfolioItems array from the data folder

import { Link, Box, Grid, Container, Button, Typography } from '@mui/material';
import HeadingWithLine from 'components/HeadingWithLine';

function PortfolioHighlight(props) {
    return (
        <Box>
            <Link underline="none" href={props.href}>
                <Box
                    component="img" sx={{
                        border: 0,
                        height: 'auto',
                        maxWidth: '100%',
                        verticalAlign: 'middle',
                        marginBottom: '25px',
                    }} src={props.img} alt="" loading="lazy" />
                <Typography variant='h2' sx={{
                    color: '#31ddb7',
                    fontWeight: 700,
                    fontSize: '30px',
                    marginBottom: '15px',
                }}>{props.title}</Typography>
            </Link>
            <Typography color='text.secondary'>{props.children}</Typography>
        </Box>
    )
}

export default function PortfolioPage() {
    return (
        <Box >
            <Container>
                <HeadingWithLine>Portfolio</HeadingWithLine>
                <Grid container spacing={5} marginBottom={'20px'}>
                    {portfolioItems.map((item, index) => (
                        <Grid item xs={6} sm={4} key={index}>
                            <PortfolioHighlight href={item.href} img={item.img} title={item.title}>
                                {item.description}
                            </PortfolioHighlight>
                        </Grid>
                    ))}
                </Grid>
                <Box align='center'>
                    <Button href="https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6" variant="outlined">View Portfolio →</Button>
                </Box>
            </Container>
        </Box >
    )
}