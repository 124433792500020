// Pages
import Portfolio from "pages/Portfolio";
import Home from "pages/Home";
import AporiaOverview from "data/aporia-beyond-the-valley-overview";

const routes = [
  {
    type: "internal",
    name: "Home",
    route: "/",
    component: <Home />,
    key: "Home"
  },
  {
    type: "internal",
    name: "Portfolio",
    route: "/portfolio",
    component: <Portfolio />,
    key: "Portfolio",
    pages: [{
      type: "internal",
      name: "Aporia Beyond the Valley",
      route: "/portfolio/aporia-beyond-the-valley",
      component: <AporiaOverview />,
      key: "aporiaBeyondTheValley"
    }]
  },
  {
    type: "external",
    name: "Download Resume",
    description: "",
    href: "https://drive.google.com/uc?export=download&amp;id=1hUXNHzlyRMDlcm1IJUE4v7gKctCFGoG6",
  },
];

export default routes;