// Portfolio page sections
import PortfolioPage from "./sections/PortfolioPage";

function Portfolio() {
  return (
    <>
      <PortfolioPage/>
    </>
  );
}

export default Portfolio;
