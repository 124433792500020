
// Material Kit 2 React Base Styles
import colors from "assets/theme/base/colors";

export default {
  styleOverrides: {
    root: {
      padding: '5px', // Adjust padding as needed
      borderRadius: '50%', // Ensure the circle shape
      color: colors.footer.icon,
      "&:hover": {
          color: colors.footer.background,
          backgroundColor: colors.footer.icon,
      },
    },
  },
};
