import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
    // Access the theme
    const theme = useTheme();

    return (
        <Box sx={{ backgroundColor: theme.palette.footer.background, color: theme.palette.footer.text }}>
            <Container sx={{
                paddingTop: '25px !important',
                paddingBottom: '25px !important',
            }}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Typography >{'© '}{new Date().getFullYear()} Mikael Peter Olsen | All Rights Reserved</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton href="http://www.facebook.com/mikaelpeterolsen" aria-label="Facebook Profile">
                        <FontAwesomeIcon icon={faFacebook} size='xs' />
                    </IconButton >
                    <IconButton href="http://www.linkedin.com/in/mikaelpeterolsen" aria-label="LinkedIn Profile">
                        <FontAwesomeIcon icon={faLinkedinIn} size='xs' />
                    </IconButton >
                </Stack>
            </Container>
        </Box>
    );
}