import { lazy, Suspense } from "react";

// Home page sections
const Hero = lazy(() => import("./sections/Hero"));
const Features = lazy(() => import("./sections/Features"));
const PortfolioHighlights = lazy(() => import("./sections/PortfolioHighlights"));
const Testimonials = lazy(() => import("./sections/Testimonials"));
const Skills = lazy(() => import("./sections/Skills"));
const CallToAction = lazy(() => import("./sections/CallToAction"));
const Contact = lazy(() => import("./sections/Contact"));

function Home() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Hero />
        <Features />
        <PortfolioHighlights />
        <Testimonials />
        <Skills />
        <CallToAction />
        <Contact />
      </Suspense>
    </>
  );
}

export default Home;