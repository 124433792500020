import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { Container, IconButton, List, ListItem, ListItemButton, ListItemText, Button, Stack, Menu, MenuItem, Typography, Drawer, SwipeableDrawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import routes from 'routes'; // Import routes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

const { secondary } = colors;

// Styled Menu Button
const StyledMenuButton = styled(Button)(() => ({
    textTransform: 'none',
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.3px',
    fontSize: pxToRem(15),
    borderBottom: '2px solid transparent',
    borderRadius: 0,
    padding: 0,
    ":hover": {
        borderColor: secondary.main,
        opacity: '0.8',
        backgroundColor: 'transparent',
    },
}));

export default function ElevateAppBar({ disableScrollEffect = false }) {

    const [mobileOpen, setMobileOpen] = React.useState(false); // Drawer starts closed

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const handleDrawerClose = () => {
        setMobileOpen((prevState) => false);
    };

    // Use `useScrollTrigger` to determine scroll position
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 5, // Adjust the threshold if needed
    });

    // Determine settings based on scroll position
    // const appBarElevation = trigger ? 3 : 0;
    // const appBarBackgroundColor = trigger ? 'white' : 'transparent';
    // const logosrc = trigger ? '/assets/images/LogoBlack-3.png' : '/assets/images/LogoWhite-6.png';
    // const iconColor = trigger ? 'text.primary' : 'white';

    // Determine settings based on scroll position or disableScrollEffect prop
    const appBarElevation = (disableScrollEffect || trigger) && !mobileOpen ? 3 : 0;
    const appBarBackgroundColor = disableScrollEffect || trigger ? 'white' : 'transparent';
    const logosrc = disableScrollEffect || trigger ? '/assets/images/LogoBlack-3.png' : '/assets/images/LogoWhite-6.png';
    const iconColor = disableScrollEffect || trigger ? 'text.primary' : 'white';
    const appBarReservedHeight = disableScrollEffect ? '64px' : '0px';

    const iconBorderColor = disableScrollEffect || trigger ? 'rgba(0,0,0, 0.15);' : 'rgba(255,255,255, 0.15);';

    // Filter internal routes for navigation
    const navItems = routes;//.filter((route) => route.route); // Only use routes with defined paths

    const drawer = (
        <Box onClick={handleDrawerToggle}>
            <Stack direction="column">
                {/* <List> */}
                {navItems.map(({ name, route }) => (
                    // <ListItem key={name} disablePadding sx={{
                    //     borderTop: '1px solid rgba(255, 255, 255, 0.25)',
                    //     paddingLeft: '10px',
                    //     paddingRight: '10px',
                    // }}>
                    <Box
                        sx={{
                            borderTop: `1px solid ${iconBorderColor}`,
                            textAlign: 'right',
                            width: '100%',
                        }}>
                        <Button
                            component={Link}
                            to={route}
                            sx={{
                                textAlign: 'right',
                                textTransform: 'none',
                                fontWeight: 500,
                                lineHeight: '1.6',
                                letterSpacing: '0.3px',
                                fontSize: pxToRem(15),
                                borderBottom: '2px solid transparent',
                                borderRadius: 0,
                                padding: 0,
                                ":hover": {
                                    borderColor: secondary.main,
                                    opacity: '0.8',
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <ListItemText primary={name} sx={{ color: iconColor }} />
                        </Button>
                    </Box>
                    //</ListItem>
                ))}
                {/* </List> */}
            </Stack>
        </Box>
    );

    return (
        <Box sx={{ height: appBarReservedHeight }}>
            <AppBar disableGutters
                elevation={appBarElevation}
                component="nav"
                sx={{
                    backgroundColor: appBarBackgroundColor,
                    borderBottom: `1px solid ${iconBorderColor}`,
                    position: 'fixed',
                    zIndex: 2000,
                }}
            >
                <Container sx={{ padding: "0px !important" }}>
                    <Toolbar sx={{ justifyContent: 'end' }}>
                        <Box sx={{ flexGrow: 1, display: 'flex', width: '100%', justifyContent: { xs: 'center', md: 'left' }, position: { xs: 'absolute', md: 'relative' }, left: '0px' }}>
                            <Link to={'/'} >
                                <Box
                                    component="img"
                                    src={logosrc}
                                    alt="logo"
                                    loading="lazy"
                                    paddingLeft={{ xs: '0px', sm: '0px', md: '50px' }}
                                />
                            </Link>
                        </Box>
                        <IconButton
                            onClick={handleDrawerToggle}
                            sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' }, color: iconColor }}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>

                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'none', md: 'inline-flex' } }}>
                            {navItems.map(({ name, route }) => (
                                <StyledMenuButton key={name} component={Link} to={route} sx={{ color: iconColor }}>
                                    {name}
                                </StyledMenuButton>
                            ))}
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            <SwipeableDrawer
                anchor='top'
                variant="persistent"
                open={mobileOpen}
                backgroundColor={appBarBackgroundColor}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    elevation: 3,
                    sx: {
                        backgroundColor: appBarBackgroundColor,
                        zIndex: (theme) => theme.zIndex.appBar - 1,
                        width: '100%',
                    }
                }}
                sx={{
                    display: { xs: 'block', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                {drawer}
            </SwipeableDrawer>
        </Box>
    );
}
