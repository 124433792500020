import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorFallback from './components/ErrorFallback';

// Locate the root element
const domNode = document.getElementById('root');
// Create the React root
const root = ReactDOM.createRoot(domNode);

// Render the application
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary fallback={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
