/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Kit 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {
  values: { xs, sm, md, lg, xl, xxl },
} = breakpoints;

const XS = `@media (min-width: ${xs}px)`;
const SM = `@media (min-width: ${sm}px)`;
const MD = `@media (min-width: ${md}px)`;
const LG = `@media (min-width: ${lg}px)`;
const XL = `@media (min-width: ${xl}px)`;
const XXL = `@media (min-width: ${xxl}px)`;

const sharedClasses = {
  paddingTop: `${pxToRem(66)} !important`,
  paddingBottom: `${pxToRem(66)} !important`,
  paddingRight: `${pxToRem(28)} !important`,
  paddingLeft: `${pxToRem(28)} !important`,
  marginRight: "auto !important",
  marginLeft: "auto !important",
  width: "100% !important",
  position: "relative",
};

export default {
  [XS]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: `${sm}px !important`,
    },
  },
  [SM]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: `${sm}px !important`,
    },
  },
  [MD]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: `${md}px !important`,
    },
  },
  [LG]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "960px !important",
    },
  },
  [XL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1140px !important",
    },
  },
  [XXL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1320px !important",
    },
  },
};
